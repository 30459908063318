define("discourse/plugins/discourse-dev-additions/discourse/connectors/user-custom-preferences/employee-contract-start-date", ["exports", "@glimmer/component", "@ember/object", "discourse/components/date-input", "discourse-i18n", "@ember/component", "@ember/template-factory"], function (_exports, _component, _object, _dateInput, _discourseI18n, _component2, _templateFactory) {
  "use strict";

  Object.defineProperty(_exports, "__esModule", {
    value: true
  });
  _exports.default = void 0;
  class EmployeeContractStartDate extends _component.default {
    static shouldRender(args, _ref) {
      let {
        siteSettings
      } = _ref;
      return siteSettings.dev_additions_enabled;
    }
    onChangeDate(date) {
      this.args.outletArgs.model.set("custom_fields.employee_contract_start_date", date?.format("YYYY-MM-DD") || "");
    }
    static #_ = (() => dt7948.n(this.prototype, "onChangeDate", [_object.action]))();
    static #_2 = (() => (0, _component2.setComponentTemplate)((0, _templateFactory.createTemplateFactory)(
    /*
      
        <div class="control-group">
          <label class="control-label">
            {{i18n "discourse_dev_additions.employee_contract_start_date.title"}}
          </label>
    
          <div class="controls">
            <DateInput
              @date={{@outletArgs.model.custom_fields.employee_contract_start_date}}
              @onChange={{this.onChangeDate}}
            />
          </div>
        </div>
      
    */
    {
      "id": "ugeXNBt/",
      "block": "[[[1,\"\\n    \"],[10,0],[14,0,\"control-group\"],[12],[1,\"\\n      \"],[10,\"label\"],[14,0,\"control-label\"],[12],[1,\"\\n        \"],[1,[28,[32,0],[\"discourse_dev_additions.employee_contract_start_date.title\"],null]],[1,\"\\n      \"],[13],[1,\"\\n\\n      \"],[10,0],[14,0,\"controls\"],[12],[1,\"\\n        \"],[8,[32,1],null,[[\"@date\",\"@onChange\"],[[30,1,[\"model\",\"custom_fields\",\"employee_contract_start_date\"]],[30,0,[\"onChangeDate\"]]]],null],[1,\"\\n      \"],[13],[1,\"\\n    \"],[13],[1,\"\\n  \"]],[\"@outletArgs\"],false,[]]",
      "moduleName": "/var/www/discourse/app/assets/javascripts/discourse/discourse/plugins/discourse-dev-additions/discourse/connectors/user-custom-preferences/employee-contract-start-date.js",
      "scope": () => [_discourseI18n.i18n, _dateInput.default],
      "isStrictMode": true
    }), this))();
  }
  _exports.default = EmployeeContractStartDate;
});